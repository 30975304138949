import axios from 'axios'

import store from '@/store'
import router from '@/router'

const SAFEROUTES = ['/follow', '/login', '/']

export default transformRequest => {
  const headers = {
    accept: 'application/json',
    'Accept-Language': localStorage.getItem('language') || 'pt'
  }

  if (store.state.session.token) {
    headers.Authorization = store.state.session.token
  }

  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: headers,
    transformRequest: transformRequest
  })

  instance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response) {
        console.log(error.response)

        if (error.response.status === 401 || error.response.status === 403) {
          store.dispatch('logout')
          if (!SAFEROUTES.includes(router.currentRoute.path)) {
            router.push('/login')
          }
        }

        return Promise.reject(error)
      }
    }
  )

  return instance
}
