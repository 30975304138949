import Vue from 'vue'
import App from './App.vue'
import IdleVue from 'idle-vue'
import Buefy, { Navbar, Dropdown, Carousel, Dialog, Loading, Checkbox, Input, Field, Button, Message, Modal, Numberinput } from 'buefy'
import VueTelInput from 'vue-tel-input'

import router from './router'
import store from './store'
import i18n from './i18n'

import './registerServiceWorker'
import './styles/main.scss'

import { faMinus, faPlus, faBell, faGlobeEurope, faArrowDown, faSms } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faMinus, faPlus, faBell, faGlobeEurope, faArrowDown, faSms)
Vue.component('fa', FontAwesomeIcon)

Vue.use(Buefy, {
  defaultIconComponent: 'fa',
  defaultIconPack: 'fas'
})

Vue.use(VueTelInput)
Vue.use(Navbar)
Vue.use(Button)
Vue.use(Field)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(Carousel)
Vue.use(Message)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Modal)
Vue.use(Numberinput)

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 120000,
  startAtIdle: false
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
