<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  created () {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  methods: {
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true

      this.$buefy.dialog.confirm({
        type: 'is-primary',
        title: `${this.$i18n.t('new_version.title')}`,
        message: `${this.$i18n.t('new_version.message')}`,
        indefinite: true,
        queue: false,
        confirmText: this.$i18n.t('new_version.confirm_text'),
        canCancel: false,
        onConfirm: () => {
          this.refreshApp()
        }
      })
    },
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    }
  }
}
</script>
