import Api from '@/services/Api'

export default {
  base () {
    const api = Api()
    api.defaults.baseURL = process.env.VUE_APP_BASE_API_URI
    return api
  },

  async getRooms () {
    const response = await this.base().get('/get/ocupancy')
    return response.data.rooms
  },

  async getNotifications () {
    const response = await this.base().get('/get/notifications')
    return response.data
  },

  async setNotification (minAvailableSeats, roomId, notificationPhone) {
    const response = await this.base().post('/set/notification', {
      min_available_seats: minAvailableSeats,
      room_id: roomId,
      notification_phone: notificationPhone
    })
    return response.data
  },

  async deleteNotification (key) {
    const response = await this.base().delete('/clear/notification/' + key)
    return response.data
  }
}
