import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ServiceBase from '@/services/ServiceBase'

Vue.use(Vuex)

const store = {
  activeRoom: 1,
  readingRoom: {
    id: 1,
    name: '',
    total_seats: 0,
    free_seats: 0,
    seats: []
  },
  attachedRoom: {
    id: 2,
    name: '',
    total_seats: 0,
    free_seats: 0,
    seats: []
  },
  multimediaRoom: {
    id: 3,
    name: '',
    total_seats: 0,
    free_seats: 0,
    seats: []
  },
  notifications: [],
  session: {
    token: localStorage.getItem('session.token')
  }
}
const actions = {
  async loadNotifications ({ commit }) {
    const notifications = await ServiceBase.getNotifications()
    store.notifications = notifications.set_notifications
  },
  async loadRoomsData ({ commit }) {
    const result = await ServiceBase.getRooms()
    result.forEach(room => {
      if (room.id === 1) {
        commit('setReadingRoom', room)
      } else if (room.id === 5) {
        commit('setAttachedRoom', room)
      } else if (room.id === 2) {
        commit('setMultimediaRoom', room)
      }
    })
  },
  setActiveRoom ({ commit }, { room }) {
    commit('setActiveRoom', room)
  },
  setSession ({ commit }, { session }) {
    commit('setSession', session)
  },
  logout ({ commit }) {
    commit('setLogout')
  }
}
const mutations = {
  setLogout (state) {
    state.session = {}
    localStorage.removeItem('session.token')
    delete axios.defaults.headers.common.Authorization
  },
  setSession (state, session) {
    state.session = session
    localStorage.setItem('session.token', session.token)
  },
  setActiveRoom (state, room) {
    state.activeRoom = room
  },
  setReadingRoom (state, room) {
    state.readingRoom = room
  },
  setAttachedRoom (state, room) {
    state.attachedRoom = room
  },
  setMultimediaRoom (state, room) {
    state.multimediaRoom = room
  }
}

export default new Vuex.Store({
  state: store,
  mutations: mutations,
  actions: actions
})
