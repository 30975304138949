import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/Home')
const Follow = () => import('../views/Follow')
const Photos = () => import('../views/Photos')
const Threesixty = () => import('../views/Threesixty')
const Login = () => import('../views/Login')
const Logout = () => import('../views/Logout')
const Notifications = () => import('../views/Notifications')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/follow',
    name: 'Follow',
    component: Follow
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/photos',
    name: 'Photos',
    component: Photos
  },
  {
    path: '/threesixty',
    name: 'Threesixty',
    component: Threesixty
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
